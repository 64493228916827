export const Currencies = {
    Euro: "€",
    Dollar: "$",
    Pound: "£",
    Dirham: "DH",
    Franc: "Fr"
}


export const getCurrencyCode = (currency: string): string => {
    switch (currency) {
        case "Euro":
            return 'EUR';

        case "Dollar":
            return 'USD';

        case "Pound":
            return 'GBP';

        case "Dirham":
            return 'AED';

        case "Franc":
            return 'CHW';

        default:
            return '';
    }
}