import { Box, IconButton } from '@mui/material';
import { Map as AzureMap } from 'azure-maps-control';
import React from 'react';
import iconCar from 'src/assets/icons/icon-car.svg';
import { barAction, MapActionBar } from '../../../shared/components/MapActionBar/MapActionBar';
import { MeasurementSystemType } from '../../../shared/models/MeasurementSystemType';
import { ProjectThumbnailModel } from '../../Home/models/ProjectThumbnailModel';
import { RoadSectionViewData } from '../models/RoadSectionViewData';
import { RoadsSectionsSummaryModel } from '../models/RoadsSectionsSummaryModel';
import { RoadsSectionsSummaryComponent } from './RoadsSectionsSummaryComponent';

interface MapWithImagesComponentProps {
    loading: boolean,
    selectedRoadSection: RoadSectionViewData,
    role: string,
    selectedRoadsSectionsSummary: RoadsSectionsSummaryModel,
    activeQualities: Set<number>,
    displayedSectionsIds: Set<number>,
    auscultationsIdsString: string,
    selectedProject: ProjectThumbnailModel,
    handleCloseRoadSectionDetails: () => void,
    azureMap: AzureMap,
    currentMeasurementSystemType: MeasurementSystemType
}

export const MapWithImagesComponent = (props: MapWithImagesComponentProps): JSX.Element => {

    const {
        loading,
        selectedRoadSection,
        handleCloseRoadSectionDetails,
        azureMap,
        selectedRoadsSectionsSummary
    } = props;

    const map: JSX.Element = (
        <>
            <div id="map"></div>
            {!loading && (
                <>
                    <Box className="map-actions" display="flex" flexDirection="column" alignItems="center">
                        {azureMap &&
                            <MapActionBar
                                azureMap={azureMap}
                                actions={[barAction.Measure]}
                                onSelectedSectionChange={null}
                                selectedSectionsId={[]}
                                sections={null}
                                mainLayer={null}
                                currentMeasurementSystemType={props.currentMeasurementSystemType}
                            />
                        }
                        <Box display="flex" className={`car-icon ${selectedRoadSection ? 'selected' : ''}`}>
                            <IconButton onClick={handleCloseRoadSectionDetails}>
                                <img src={iconCar} alt="car icon" />
                            </IconButton>
                        </Box>
                    </Box>
                </>
            )}
        </>
    );

    return (
        <Box display="flex" flexDirection="column" className="map-content">
            {map}
            {!loading ?
                <RoadsSectionsSummaryComponent
                    selectedRoadsSectionsSummary={selectedRoadsSectionsSummary}
                    activeQualities={props.activeQualities}
                    role={props.role}
                    displayedSectionsIds={props.displayedSectionsIds}
                    selectedProject={props.selectedProject}
                    auscultationsIdsString={props.auscultationsIdsString}
                />
                : ''}
        </Box>
    );
}