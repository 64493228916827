import { Box } from '@mui/material';
import { SvgIcon } from '@progress/kendo-react-common';
import { SortDescriptor } from '@progress/kendo-data-query';
import { GridHeaderCellProps } from '@progress/kendo-react-grid';
import React, { useEffect, useState } from 'react';
import { RoadSectionGridViewData } from '../../models/RoadSectionGridViewData';
import { Checkbox } from '@progress/kendo-react-inputs';
import {
    sortAscSmallIcon,
    sortDescSmallIcon,
} from '@progress/kendo-svg-icons';

interface CustomCheckboxHeaderCellProperties extends GridHeaderCellProps {
    displayedRoadSectionAttributes: RoadSectionGridViewData[],
    onHeaderSelectionChange: (checked: boolean) => void,
    sort?: SortDescriptor[],
    sortingField: string,
    onCheckboxSortChange?: (sortItems: SortDescriptor[]) => void,
}

export const CustomCheckboxHeaderCell = (props: CustomCheckboxHeaderCellProperties) => {

    const [checked, setChecked] = useState<boolean>(false);

    let sortIconClass = "k-icon k-i-sort-desc-small whithout-color";
    let currentsort;
    let sortIndex = -1;
    if (props.sort?.length > 0) {
        let currentSortIndex: number = props.sort.findIndex(x => x.field === props.sortingField);
        if (currentSortIndex !== -1) {
            sortIconClass = props.sort[currentSortIndex].dir === "asc" ? "k-icon k-svg-icon k-i-sort-asc-small" : props.sort[currentSortIndex].dir === "desc" ? "k-icon k-svg-icon k-i-sort-desc-small" : "k-icon k-svg-icon k-i-sort-desc-small";
            currentsort = props.sort[currentSortIndex].dir;
            sortIndex = currentSortIndex;
        }
    }

    const handleSortChange = () => {
        let sortItems: SortDescriptor[] = null;
        if (props.sort?.length > 0 && props.sort[0].dir === 'asc' && props.sort[0].field === props.sortingField) {
            sortItems = [{ field: props.sortingField, dir: 'desc' }];
        } else if (props.sort?.length > 0 && props.sort[0].dir === 'desc' && props.sort[0].field === props.sortingField) {
            sortItems = [];
        } else {
            sortItems = [{
                field: props.sortingField, dir: 'asc'
            }];
        }
        props.onCheckboxSortChange(sortItems);
    }

    const isChecked = (roadSectionAttributes: RoadSectionGridViewData[]): boolean => {
        if (roadSectionAttributes.every(x => x.isSelected)) {
            return true;
        }
        else if (roadSectionAttributes.every(x => !x.isSelected)) {
            return false;
        }
        else
            return null;

    }

    useEffect(() => {
        setChecked(isChecked(props.displayedRoadSectionAttributes));
    }, [JSON.stringify(props.displayedRoadSectionAttributes)]);

    const onHeaderSelectionChange = () => {
        let ischecked = isChecked(props.displayedRoadSectionAttributes);
        props.onHeaderSelectionChange(ischecked === null ? true : !ischecked);
    }
    return (
        <Box display="flex" flexDirection="row">
            <Checkbox onChange={(e) => onHeaderSelectionChange()} checked={checked} className='kendo-checkbox'></Checkbox>
            <Box className="column-mouse-hover" minWidth="20px" onClick={handleSortChange}>
                <span className={sortIconClass}>
                    {currentsort === "asc" ? <SvgIcon icon={sortAscSmallIcon} className='k-sort-icon ' /> : currentsort === "desc" ? <SvgIcon icon={sortDescSmallIcon} className='k-sort-icon ' /> : <SvgIcon icon={sortDescSmallIcon} />}
                </span>
                {props.sort?.length > 1 && sortIndex !== -1 &&
                    <span className="k-sort-order">{(sortIndex + 1)}</span>
                }
            </Box>
        </Box>
    );
}
