import { Currencies } from "../../../shared/models/Currencies";
import { ProjectVersion } from "../../Home/services/dataContracts/queryStack/ProjectVersion";
import { CostRatio } from "../../ProjectSettings/services/dataContracts/queryStack/CostRatio";
import { QualityKind } from "../../ProjectSettings/services/dataContracts/queryStack/QualityKind";
import { WorkPriority } from "../../ProjectSettings/services/dataContracts/queryStack/WorkPriority";
import { MergedProjectVersion } from "../../RoadsCondition/models/MergedProjectVersion";
import { RoadSectionViewData } from "../../RoadsCondition/models/RoadSectionViewData";
import { ScoreColors } from "../../RoadsCondition/models/ScoreColors";
import { RoadsConditionAndScenariosShared } from "../../RoadsCondition/RoadsConditionAndScenariosShared";
import { Traffic } from "../../RoadsCondition/services/RoadsCondition/dataContracts/queryStack/Traffic";
import { RoadSection } from "../models/RoadSection";
import { Scenario } from "../services/dataContracts/queryStack/Scenario";
import { ScenariosApiClient } from "../services/ScenariosApiClient";
import { SectionsSummaryModel } from "./CommonComponents/SectionsSummaryModel";

export default class ScenariosUtilities {

    public static computeSummary = (sectionsIds: Set<number>, roadsSections: Map<number, RoadSectionViewData>, sections: Map<number, RoadSection>): SectionsSummaryModel => {
        let totalSelectedSectionsLengthInMeters = 0;
        let totalSelectedSectionsSurface = 0;

        let monitoringQualitySelectedSectionsLengthInMeters = 0;
        let localizedRepairQualitySelectedSectionsLengthInMeters = 0;
        let generalMaintenanceQualitySelectedSectionsLengthInMeters = 0;
        let reinforcementQualitySelectedSectionsLengthInMeters = 0;
        let rehabilitationQualitySelectedSectionsLengthInMeters = 0;

        let monitoringQualitySelectedSectionsSurface = 0;
        let localizedRepairQualitySelectedSectionsSurface = 0;
        let generalMaintenanceQualitySelectedSectionsSurface = 0;
        let reinforcementQualitySelectedSectionsSurface = 0;
        let rehabilitationQualitySelectedSectionsSurface = 0;

        let monitoringQualitySelectedSectionsCost = 0;
        let localizedRepairQualitySelectedSectionsCost = 0;
        let generalMaintenanceQualitySelectedSectionsCost = 0;
        let reinforcementQualitySelectedSectionsCost = 0;
        let rehabilitationQualitySelectedSectionsCost = 0;

        sectionsIds.forEach((sectionId: number) => {
            let section = roadsSections.get(sectionId);
            let lengthInMeters = Math.round(section.lengthInMeters);
            let surface = Math.round(lengthInMeters * section.widthInMeters);
            let relatedSection = sections.get(sectionId);
            let cost = relatedSection?.cost;

            switch (section.scoreColor) {
                case ScoreColors.monitoring:
                    monitoringQualitySelectedSectionsLengthInMeters += lengthInMeters;
                    monitoringQualitySelectedSectionsSurface += surface;
                    monitoringQualitySelectedSectionsCost += cost ?? 0;
                    break;

                case ScoreColors.localizedRepair:
                    localizedRepairQualitySelectedSectionsLengthInMeters += lengthInMeters;
                    localizedRepairQualitySelectedSectionsSurface += surface;
                    localizedRepairQualitySelectedSectionsCost += cost ?? 0;
                    break;

                case ScoreColors.generalMaintenance:
                    generalMaintenanceQualitySelectedSectionsLengthInMeters += lengthInMeters;
                    generalMaintenanceQualitySelectedSectionsSurface += surface;
                    generalMaintenanceQualitySelectedSectionsCost += cost ?? 0;
                    break;

                case ScoreColors.reinforcement:
                    reinforcementQualitySelectedSectionsLengthInMeters += lengthInMeters;
                    reinforcementQualitySelectedSectionsSurface += surface;
                    reinforcementQualitySelectedSectionsCost += cost ?? 0;
                    break;

                case ScoreColors.rehabilitation:
                    rehabilitationQualitySelectedSectionsLengthInMeters += lengthInMeters;
                    rehabilitationQualitySelectedSectionsSurface += surface;
                    rehabilitationQualitySelectedSectionsCost += cost ?? 0;
                    break;

                default:
                    break;
            }

            totalSelectedSectionsLengthInMeters = (section.roadSectionScoreId !== null) ? totalSelectedSectionsLengthInMeters + lengthInMeters : totalSelectedSectionsLengthInMeters;
            totalSelectedSectionsSurface = (section.roadSectionScoreId !== null) ? totalSelectedSectionsSurface + surface : totalSelectedSectionsSurface;
        });

        return {
            totalSelectedSectionsCount: sectionsIds.size,

            totalSelectedSectionsLengthInMeters,
            totalSelectedSectionsSurface,

            monitoringQualitySelectedSectionsLengthInMeters,
            localizedRepairQualitySelectedSectionsLengthInMeters,
            generalMaintenanceQualitySelectedSectionsLengthInMeters,
            reinforcementQualitySelectedSectionsLengthInMeters,
            rehabilitationQualitySelectedSectionsLengthInMeters,

            monitoringQualitySelectedSectionsSurface,
            localizedRepairQualitySelectedSectionsSurface,
            generalMaintenanceQualitySelectedSectionsSurface,
            reinforcementQualitySelectedSectionsSurface,
            rehabilitationQualitySelectedSectionsSurface,

            monitoringQualitySelectedSectionsPercent: totalSelectedSectionsLengthInMeters ? (monitoringQualitySelectedSectionsLengthInMeters * 100) / totalSelectedSectionsLengthInMeters : 0,
            localizedRepairQualitySelectedSectionsPercent: totalSelectedSectionsLengthInMeters ? (localizedRepairQualitySelectedSectionsLengthInMeters * 100) / totalSelectedSectionsLengthInMeters : 0,
            generalMaintenanceQualitySelectedSectionsPercent: totalSelectedSectionsLengthInMeters ? (generalMaintenanceQualitySelectedSectionsLengthInMeters * 100) / totalSelectedSectionsLengthInMeters : 0,
            reinforcementQualitySelectedSectionsPercent: totalSelectedSectionsLengthInMeters ? (reinforcementQualitySelectedSectionsLengthInMeters * 100) / totalSelectedSectionsLengthInMeters : 0,
            rehabilitationQualitySelectedSectionsPercent: totalSelectedSectionsLengthInMeters ? (rehabilitationQualitySelectedSectionsLengthInMeters * 100) / totalSelectedSectionsLengthInMeters : 0,

            monitoringQualitySelectedSectionsCost,
            localizedRepairQualitySelectedSectionsCost,
            generalMaintenanceQualitySelectedSectionsCost,
            reinforcementQualitySelectedSectionsCost,
            rehabilitationQualitySelectedSectionsCost
        } as SectionsSummaryModel;
    }

    public static getScenarioAndProjectSettingsData = async (projectId: string, scenarioId: number, mergedProjectAuscultationsCache: Map<number, MergedProjectVersion>, projectVersionsCache: Map<number, ProjectVersion>): Promise<{ scenario: Scenario, projectCurrency: string, mergedProject: MergedProjectVersion, workPriorities: WorkPriority[], costRatios: CostRatio[] }> => {

        const scenarioData = await ScenariosApiClient.GetScenario(scenarioId);
        let scenario = scenarioData.data ?? null;

        const apiData = await Promise.all([
            RoadsConditionAndScenariosShared.getMergedProject(scenario.projectVersionId, mergedProjectAuscultationsCache, projectVersionsCache),
            ScenariosApiClient.GetProjectCurrency(projectId),
            ScenariosApiClient.GetWorkPriorities(projectId),
            ScenariosApiClient.GetCostRatios(projectId, scenario.year)
        ]);

        let mergedProject = apiData[0];
        let projectCurrency = Currencies[apiData[1].data];
        let workPriorities = apiData[2].data;
        let costRatios = apiData[3].data;

        return {
            scenario,
            mergedProject,
            projectCurrency,
            workPriorities,
            costRatios
        }
    }

    public static getSectionDataWhenHasImportance = (importance: number, score: number, traffic: Traffic, workPriorities: WorkPriority[], costRatios: CostRatio[]): { workPriority: WorkPriority, costRatio: CostRatio } => {
        let workPriority: WorkPriority = null;
        let costRatio: CostRatio = costRatios.find(x => x.traffic === traffic && x.score === score);

        if (importance) {
            let sectionQuality = ScenariosUtilities.getSectionQuality(score);
            workPriority = workPriorities.find(x => x.importance === importance && x.quality === sectionQuality);
        }

        return {
            workPriority,
            costRatio
        }
    }

    public static updateSectionData = (section: RoadSection, sectionData: { workPriority: WorkPriority, costRatio: CostRatio }): void => {
        section.lengthInMetersGridValue = section.lengthInMeters ? Math.round(section.lengthInMeters) : null;
        section.widthInMeters = section.widthInMeters ?? null;
        section.surface = section.lengthInMetersGridValue && section.widthInMeters ? Math.round(section.lengthInMetersGridValue * section.widthInMeters) : null;
        section.workPriority = sectionData.workPriority ? sectionData.workPriority.value : null;
        section.costRatio = sectionData.costRatio ? sectionData.costRatio.costRatioValue : null;
        section.cost = section.surface && section.costRatio ? section.surface * section.costRatio : null;
        section.costGridValue = section.cost ? section.cost.toFixedLocalized(0) : null;
    }

    public static getSectionQuality = (score: number): QualityKind => {
        return score === 1 ? QualityKind.rehabilitation :
            (score === 2 || score === 3 ? QualityKind.reinforcement :
                (score === 4 || score === 5 ? QualityKind.generalMaintenance :
                    (score === 6 || score === 7 ? QualityKind.localizedRepair :
                        (score === 8 || score === 9 || score === 10 ? QualityKind.monitoring : null))));
    }
}
