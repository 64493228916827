import React from "react";
import { Button, ButtonGroup } from '@mui/material';
import mapButtonIcon from 'src/assets/icons/icon-map-button.svg';
import ViewListIcon from '@mui/icons-material/ViewList';
import Translate from '../../../localization/Localization';
import { DisplayMode } from "../HighwaysView";
import './ActionBarComponent.scss';
import CompareIcon from '@mui/icons-material/Compare';
import layersIcon from 'src/assets/icons/icon-layers.svg';

interface ActionBarViewProps {
    displayMode: DisplayMode,
    updateDisplayMode: (displayMode: DisplayMode) => void,
    UpdateLayerMenuDisplay: () => void
    isLayerMenuDisplayed: boolean
}

export const ActionBarComponent = (props: ActionBarViewProps) => {

    return (
        <div className="actions-bar-highways">
            <ButtonGroup variant="contained" aria-label="button group" className="button-group">
                <Button className={`button map ${props.displayMode === DisplayMode.Map ? 'active' : ''}`} onClick={() => props.updateDisplayMode(DisplayMode.Map)}>
                    <img src={mapButtonIcon} alt="map button icon" />
                    <span className="label">{Translate.Resources.UI_ActionsMenu_Map}</span>
                </Button>
                <Button className={`button ${props.displayMode === DisplayMode.MapAndGrid ? 'active' : ''}`} onClick={() => props.updateDisplayMode(DisplayMode.MapAndGrid)}>
                    <CompareIcon className="view-list-icon" />
                    <span className="label">{Translate.Resources.UI_ActionsMenu_Map_Table}</span>
                </Button>
                <Button className={`button grid ${props.displayMode === DisplayMode.Grid ? 'active' : ''}`} onClick={() => props.updateDisplayMode(DisplayMode.Grid)}>
                    <ViewListIcon className="view-list-icon" />
                    <span className="label">{Translate.Resources.UI_ActionsMenu_Table}</span>
                </Button>
            </ButtonGroup>
            <div>
                <Button className={!props.isLayerMenuDisplayed ? "btn-secondary" : "btn-secondary is-selected"} onClick={() => props.UpdateLayerMenuDisplay()}>
                    <img src={layersIcon } alt="layers icon" />
                    <span className="label">{Translate.Resources.UI_ActionsMenu_Layers}</span>
                </Button>
            </div>
        </div>
    );
}