import DeleteIcon from '@mui/icons-material/Delete';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Button, Dialog, DialogContent } from '@mui/material';
import { Box } from '@mui/system';
import { Grid, GridCellProps, GridColumn, GridItemChangeEvent, GridRowProps } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import Translate, { Localization } from '../../../../localization/Localization';
import { PieChartComponent } from '../../../../shared/components/PieChart/PieChartComponent';
import { MeasurementSystem } from '../../../../utils/MeasurementSystem';
import { CostRatio } from '../../../ProjectSettings/services/dataContracts/queryStack/CostRatio';
import { MergedProjectVersion } from '../../../RoadsCondition/models/MergedProjectVersion';
import { ProgrammingAreaExtended } from '../../models/ProgrammingAreaExtended';
import { TypeOfWorkSelectModel } from '../../models/TypeOfWorkSelectModel';
import { ProgrammingArea } from '../../services/dataContracts/queryStack/ProgrammingArea';
import { ProgrammingAreaStatus } from '../../services/dataContracts/queryStack/ProgrammingAreaStatus';
import { WorkOrientation } from '../../services/dataContracts/queryStack/WorkOrientation';
import './AddOrEditAreaStyles.scss';
import { AreaNumericCell } from './AreaNumericCell';
import { CustomColorPickerCell } from './CustomColorPickerCell';
import { CustomDatePickerCell } from './CustomDatePickerCell';
import { CustomProgrammingAreaStatusCell } from './CustomProgrammingAreaStatusCell';
import { CustomSelectTypeOfWorkCell } from './CustomSelectTypeOfWorkCell';
import { CustomWorkOrientationCell } from './CustomWorkOrientationCell';
import { CellRender, RowRender } from './Renderers';

const EDIT_FIELD = "inEditField";

interface AddOrEditAreaComponentProps {
    isNew: boolean;
    areaToEdit: ProgrammingArea;
    mergedProject: MergedProjectVersion,
    currency: string,
    typesOfWorks: string[],
    costRatios: CostRatio[],
    selectedSectionsIds: Set<number>,
    handleCancel: () => void,
    handleAddOrUpdateProgrammingArea: (areaLabel: string,
        status: ProgrammingAreaStatus,
        workOrientation: WorkOrientation,
        selectedWork: string,
        hexColor: string,
        startDate: Date,
        endDate: Date,
        remarks: string,
        costRatio: number,
        costRatioIsOverriden: boolean,
        budgetAmount: number,
        lengthInLinearMeters: number,
        lengthInLinearMetersIsOverridden: boolean,
        areaInSquareMeters: number,
        areaInSquareMetersIsOverridden: boolean,
        programmingAreaId?: number) => void,
    handleDeleteArea: (programmingAreaId: number) => void,
}

export const AddOrEditAreaComponent = (props: AddOrEditAreaComponentProps): JSX.Element => {

    const [gridData, setGridData] = useState<ProgrammingAreaExtended[]>([]);
    const [typesOfWorksUsedInProject, setTypesOfWorksUsedInProject] = useState<TypeOfWorkSelectModel[]>([]);
    const [isConfirmCancelDialogOpened, setIsConfirmCancelDialogOpened] = useState<boolean>(false);

    useEffect(() => {
        if (props.isNew) {
            let newArea: ProgrammingAreaExtended = {
                programmingAreaId: null,
                label: '',
                hexColor: "#db00f0",
                status: ProgrammingAreaStatus.draft,
                workOrientation: null,
                selectedWork: null,
                costRatio: null,
                costRatioIsOverriden: false,
                budgetAmount: null,
                lengthInLinearMeters: null,
                lengthInLinearMetersIsOverridden: false,
                areaInSquareMeters: null,
                areaInSquareMetersIsOverridden: false,
                startDate: null,
                endtDate: null,
                sectionsId: [],
                remarks: null,
                inEditField: undefined,
                expanded: false,
                sections: [],
                lastModificationUser: null
            };
            setGridData([newArea]);
        }
        else {
            let area: ProgrammingAreaExtended = {
                ...props.areaToEdit,
                inEditField: undefined,
                expanded: false,
                sectionsId: props.areaToEdit.sections.map(x => x.roadSectionId)
            };

            if (props.selectedSectionsIds.size > 0) {
                let monitoringSectionsLengthInMeters: number = 0;
                let localizedRepairSectionsLengthInMeters: number = 0;
                let generalMaintenanceSectionsLengthInMeters: number = 0;
                let reinforcementSectionsLengthInMeters: number = 0;
                let rehabilitationSectionsLengthInMeters: number = 0;

                let weightedScoreSum = 0;
                let sumOfLengthInMeters = 0;

                props.selectedSectionsIds.forEach((sectionId: number) => {
                    let section = props.mergedProject.roadsSections.get(sectionId);
                    if (section) {
                        let lengthInMeters = Math.round(section.lengthInMeters);

                        if (section.score === 10 || section.score === 9 || section.score === 8) {
                            monitoringSectionsLengthInMeters += lengthInMeters;
                        }

                        if (section.score === 7 || section.score === 6) {
                            localizedRepairSectionsLengthInMeters += lengthInMeters;
                        }

                        if (section.score === 5 || section.score === 4) {
                            generalMaintenanceSectionsLengthInMeters += lengthInMeters;
                        }

                        if (section.score === 3 || section.score === 2) {
                            reinforcementSectionsLengthInMeters += lengthInMeters;
                        }

                        if (section.score === 1) {
                            rehabilitationSectionsLengthInMeters += lengthInMeters;
                        }

                        weightedScoreSum = lengthInMeters && section.score ? lengthInMeters * section.score + (weightedScoreSum ?? 0) : weightedScoreSum ?? 0;
                        sumOfLengthInMeters += (lengthInMeters ?? 0);
                    }
                });

                area.monitoringSectionsPercent = sumOfLengthInMeters ? (monitoringSectionsLengthInMeters * 100) / sumOfLengthInMeters : 0;
                area.localizedRepairSectionsPercent = sumOfLengthInMeters ? (localizedRepairSectionsLengthInMeters * 100) / sumOfLengthInMeters : 0;
                area.generalMaintenanceSectionsPercent = sumOfLengthInMeters ? (generalMaintenanceSectionsLengthInMeters * 100) / sumOfLengthInMeters : 0;
                area.reinforcementSectionsPercent = sumOfLengthInMeters ? (reinforcementSectionsLengthInMeters * 100) / sumOfLengthInMeters : 0;
                area.rehabilitationSectionsPercent = sumOfLengthInMeters ? (rehabilitationSectionsLengthInMeters * 100) / sumOfLengthInMeters : 0;
                area.averageScore = sumOfLengthInMeters ? weightedScoreSum / sumOfLengthInMeters : 0
            }

            setGridData([area]);
        }
    }, [props.isNew]);

    useEffect(() => {
        let typesOfWorks: TypeOfWorkSelectModel[] = [];
        props.typesOfWorks.forEach((typeOfWork: string) => {
            if (typeOfWork) {
                typesOfWorks.push({ label: typeOfWork, value: typeOfWork });
            }
        });
        setTypesOfWorksUsedInProject(typesOfWorks);
    }, [props.typesOfWorks]);

    useEffect(() => {
        if (gridData.length > 0) {
            if (props.selectedSectionsIds.size > 0) {
                let monitoringSectionsLengthInMeters: number = 0;
                let localizedRepairSectionsLengthInMeters: number = 0;
                let generalMaintenanceSectionsLengthInMeters: number = 0;
                let reinforcementSectionsLengthInMeters: number = 0;
                let rehabilitationSectionsLengthInMeters: number = 0;

                let weightedScoreSum = 0;
                let sumOfLengthInMeters = 0;

                let sumOfSurfaces = 0;
                let budgetAmount = 0;
                props.selectedSectionsIds.forEach((sectionId: number) => {
                    let section = props.mergedProject.roadsSections.get(sectionId);
                    if (section) {
                        let lengthInMeters = Math.round(section.lengthInMeters);

                        if (section.score === 10 || section.score === 9 || section.score === 8) {
                            monitoringSectionsLengthInMeters += lengthInMeters;
                        }

                        if (section.score === 7 || section.score === 6) {
                            localizedRepairSectionsLengthInMeters += lengthInMeters;
                        }

                        if (section.score === 5 || section.score === 4) {
                            generalMaintenanceSectionsLengthInMeters += lengthInMeters;
                        }

                        if (section.score === 3 || section.score === 2) {
                            reinforcementSectionsLengthInMeters += lengthInMeters;
                        }

                        if (section.score === 1) {
                            rehabilitationSectionsLengthInMeters += lengthInMeters;
                        }

                        weightedScoreSum = lengthInMeters && section.score ? lengthInMeters * section.score + (weightedScoreSum ?? 0) : weightedScoreSum ?? 0;

                        let surface = Math.round(lengthInMeters * section.widthInMeters);
                        sumOfLengthInMeters += (lengthInMeters ?? 0);
                        sumOfSurfaces += (surface ?? 0);

                        let costRatio: CostRatio = props.costRatios.find(x => x.traffic === section.traffic && x.score === section.score);
                        let sectionCostRatio = costRatio ? costRatio.costRatioValue : null;
                        let sectionBudget = surface && sectionCostRatio ? surface * sectionCostRatio : null;
                        budgetAmount += (sectionBudget ?? 0);
                    }
                });

                let newData = [...gridData];
                newData[0].lengthInLinearMeters = sumOfLengthInMeters;
                newData[0].lengthInLinearMetersIsOverridden = false;
                newData[0].areaInSquareMeters = sumOfSurfaces;
                newData[0].areaInSquareMetersIsOverridden = false;
                newData[0].sectionsId = Array.from(props.selectedSectionsIds);
                newData[0].costRatio = sumOfSurfaces ? budgetAmount / sumOfSurfaces : null;
                newData[0].costRatioIsOverriden = false;
                newData[0].budgetAmount = budgetAmount;

                newData[0].monitoringSectionsPercent = sumOfLengthInMeters ? (monitoringSectionsLengthInMeters * 100) / sumOfLengthInMeters : 0;
                newData[0].localizedRepairSectionsPercent = sumOfLengthInMeters ? (localizedRepairSectionsLengthInMeters * 100) / sumOfLengthInMeters : 0;
                newData[0].generalMaintenanceSectionsPercent = sumOfLengthInMeters ? (generalMaintenanceSectionsLengthInMeters * 100) / sumOfLengthInMeters : 0;
                newData[0].reinforcementSectionsPercent = sumOfLengthInMeters ? (reinforcementSectionsLengthInMeters * 100) / sumOfLengthInMeters : 0;
                newData[0].rehabilitationSectionsPercent = sumOfLengthInMeters ? (rehabilitationSectionsLengthInMeters * 100) / sumOfLengthInMeters : 0;
                newData[0].averageScore = sumOfLengthInMeters ? weightedScoreSum / sumOfLengthInMeters : 0;

                setGridData(newData);
            }
            else {
                let newData = [...gridData];
                newData[0].lengthInLinearMeters = null;
                newData[0].lengthInLinearMetersIsOverridden = false;
                newData[0].areaInSquareMeters = null;
                newData[0].areaInSquareMetersIsOverridden = false;
                newData[0].sections = [];
                newData[0].budgetAmount = null;
                newData[0].costRatio = null;
                newData[0].costRatioIsOverriden = false;
                newData[0].monitoringSectionsPercent = null;
                newData[0].localizedRepairSectionsPercent = null;
                newData[0].generalMaintenanceSectionsPercent = null;
                newData[0].reinforcementSectionsPercent = null;
                newData[0].rehabilitationSectionsPercent = null;
                newData[0].averageScore = null;

                setGridData(newData);
            }
        }
    }, [props.selectedSectionsIds])

    const symbolOfSurfaceUnit = MeasurementSystem.getSymbolOfSurfaceUnit();
    const symbolOfLengthUnit = MeasurementSystem.getSymbolOfLengthUnit();

    const handleAddOrUpdateProgrammingArea = (): void => {
        let label = gridData[0].label ? gridData[0].label : getDefaultLabelOfNewArea();
        props.handleAddOrUpdateProgrammingArea(
            label,
            gridData[0].status,
            gridData[0].workOrientation,
            gridData[0].selectedWork,
            gridData[0].hexColor,
            gridData[0].startDate,
            gridData[0].endtDate,
            gridData[0].remarks,
            gridData[0].costRatio,
            gridData[0].costRatioIsOverriden,
            gridData[0].budgetAmount,
            gridData[0].lengthInLinearMeters,
            gridData[0].lengthInLinearMetersIsOverridden,
            gridData[0].areaInSquareMeters,
            gridData[0].areaInSquareMetersIsOverridden,
            !props.isNew ? gridData[0].programmingAreaId : null);
    }

    const getDefaultLabelOfNewArea = (): string => {
        let label: string = "";
        let sectionsIds = props.selectedSectionsIds;
        if (sectionsIds.size > 0) {
            let roadLabels = new Set<string>();
            sectionsIds.forEach((sectionId) => {
                let section = props.mergedProject.roadsSections.get(sectionId);
                if (section && section.roadLabel && !roadLabels.has(section.roadLabel)) {
                    roadLabels.add(section.roadLabel);
                }
            });

            let labels = Array.from(roadLabels);
            label = roadLabels.size > 1 ? `${labels[0]} - ${labels[labels.length - 1]}` : labels[0];
        }

        return label ? label : Translate.Resources.UI_Programming_Area_NewArea;
    }

    const handleCancel = (): void => {
        let newAreaData = gridData[0];

        if (props.areaToEdit) {
            let oldAreaData = props.areaToEdit;

            if (oldAreaData.hexColor !== newAreaData.hexColor ||
                oldAreaData.label !== newAreaData.label ||
                oldAreaData.status !== newAreaData.status ||
                oldAreaData.workOrientation !== newAreaData.workOrientation ||
                oldAreaData.selectedWork !== newAreaData.selectedWork ||
                oldAreaData.budgetAmount !== newAreaData.budgetAmount ||
                oldAreaData.costRatio !== newAreaData.costRatio ||
                oldAreaData.lengthInLinearMeters !== newAreaData.lengthInLinearMeters ||
                oldAreaData.areaInSquareMeters !== newAreaData.areaInSquareMeters ||
                oldAreaData.startDate !== newAreaData.startDate ||
                oldAreaData.endtDate !== newAreaData.endtDate ||
                !isEqual(oldAreaData.sections.map(x => x.roadSectionId).sort(), newAreaData.sectionsId.sort()) ||
                oldAreaData.remarks !== newAreaData.remarks) {
                setIsConfirmCancelDialogOpened(true);
                return;
            }
        }

        if (props.isNew && (
            newAreaData.label !== '' ||
            newAreaData.hexColor !== "#db00f0" ||
            newAreaData.status !== ProgrammingAreaStatus.draft ||
            newAreaData.workOrientation !== null ||
            newAreaData.selectedWork !== null ||
            newAreaData.costRatio !== null ||
            newAreaData.budgetAmount !== null ||
            newAreaData.lengthInLinearMeters !== null ||
            newAreaData.areaInSquareMeters !== null ||
            newAreaData.startDate !== null ||
            newAreaData.endtDate !== null ||
            newAreaData.sections.length > 0 ||
            newAreaData.remarks !== null)) {
            setIsConfirmCancelDialogOpened(true);
            return;
        }

        props.handleCancel();
    }

    const handleDeleteArea = (programmingAreaId: number): void => {
        if (!props.isNew) {
            props.handleDeleteArea(programmingAreaId);
        }
    }

    const customCellRender = (
        td: React.ReactElement<HTMLTableCellElement>,
        props: GridCellProps
    ) => (
        <CellRender
            originalProps={props}
            td={td}
            enterEdit={enterEdit}
            editField={EDIT_FIELD}
        />
    );

    const customRowRender = (
        tr: React.ReactElement<HTMLTableRowElement>,
        props: GridRowProps
    ) => (
        <RowRender
            originalProps={props}
            tr={tr}
            exitEdit={exitEdit}
            editField={EDIT_FIELD}
        />
    );

    const enterEdit = (dataItem: ProgrammingAreaExtended, field: string) => {
        exitEdit();

        if (field === "status" || field === "workOrientation" || field === "hexColor")
            return;

        const newData = gridData.map((item) => ({
            ...item,
            [EDIT_FIELD]: item.programmingAreaId === dataItem.programmingAreaId ? field : undefined,
        }));
        setGridData(newData);
    };

    const exitEdit = () => {
        let item = gridData.find(x => x.inEditField !== undefined);
        if (item) {
            let oldAreaData = props.areaToEdit;
            switch (item.inEditField) {
                case "budgetAmount":
                    if (item.areaInSquareMeters) {
                        item.costRatio = item.budgetAmount / item.areaInSquareMeters;
                    }

                    if (!item.budgetAmount) {
                        item.costRatio = null;
                    }
                    break;

                case "costRatio":
                    if (props.isNew || (oldAreaData && oldAreaData.costRatio !== item.costRatio)) {
                        item.costRatioIsOverriden = true;
                        if (item.areaInSquareMeters) {
                            item.budgetAmount = item.costRatio * item.areaInSquareMeters;
                        }

                        if (!item.costRatio) {
                            item.budgetAmount = null;
                        }
                    }
                    break;

                case "lengthInLinearMeters":
                    if (props.isNew || (oldAreaData && oldAreaData.lengthInLinearMeters !== item.lengthInLinearMeters)) {
                        item.lengthInLinearMetersIsOverridden = true;
                    }
                    break;

                case "areaInSquareMeters":
                    if (props.isNew || (oldAreaData && oldAreaData.areaInSquareMeters !== item.areaInSquareMeters)) {
                        if (!item.areaInSquareMeters) {
                            item.areaInSquareMetersIsOverridden = false;
                            item.budgetAmount = null;
                            item.costRatio = null;
                        }
                        else {
                            item.areaInSquareMetersIsOverridden = true;
                            if (item.costRatio) {
                                item.budgetAmount = item.costRatio * item.areaInSquareMeters;
                            }
                        }
                    }
                    break;

                default:
                    break;
            }
        }

        const newData = gridData.map((item) => ({ ...item, [EDIT_FIELD]: undefined }));
        setGridData(newData);
    };

    const handleColorChanged = (dataItem: ProgrammingAreaExtended, hexColor: string) => {
        let newData = [...gridData];
        let itemIndex = newData.findIndex(x => x.programmingAreaId === dataItem.programmingAreaId);
        newData[itemIndex].hexColor = hexColor;
        setGridData(newData);
        exitEdit();
    }

    const handleStartDateChanged = (dataItem: ProgrammingAreaExtended) => {
        let newData = [...gridData];
        let itemIndex = newData.findIndex(x => x.programmingAreaId === dataItem.programmingAreaId);
        newData[itemIndex].startDate = dataItem.startDate;
        setGridData(newData);
        exitEdit();
    }

    const handleEndDateChanged = (dataItem: ProgrammingAreaExtended) => {
        let newData = [...gridData];
        let itemIndex = newData.findIndex(x => x.programmingAreaId === dataItem.programmingAreaId);
        newData[itemIndex].endtDate = dataItem.endtDate;
        setGridData(newData);
        exitEdit();
    }

    const itemChange = (event: GridItemChangeEvent): void => {
        let field = event.field || "";
        event.dataItem[field] = event.value;
        let newData = gridData.map((item) => {
            if (item.programmingAreaId === event.dataItem.programmingAreaId) {
                item[field] = event.value;
            }
            return item;
        });
        setGridData(newData);
    }

    return (
        <Box className="add-edit-area">
            <Box className="add-edit-area-grid">
                <LocalizationProvider language={Localization.locale}>
                    <IntlProvider locale={Localization.locale}>
                        <Grid
                            className="grid"
                            data={gridData}
                            dataItemKey={"programmingAreaId"}
                            sortable={false}
                            cellRender={customCellRender}
                            rowRender={customRowRender}
                            editField={EDIT_FIELD}
                            rowHeight={50}
                            onItemChange={itemChange}
                        >
                            <GridColumn field="hexColor" width="70px" title="" headerCell={() => <></>}
                                cell={(cellProps) => <CustomColorPickerCell {...cellProps} handleColorChanged={handleColorChanged} />}
                            />
                            <GridColumn field="label" width="200px" title={Translate.Resources.UI_Programmig_AreasManagement_Grid_Column_Zone} />
                            <GridColumn field="status" width="130px" title={Translate.Resources.UI_Programmig_AreasManagement_Grid_Column_Status}
                                cell={(cellProps) => <CustomProgrammingAreaStatusCell {...cellProps} />}
                            />
                            <GridColumn field="workOrientation" width="180px" title={Translate.Resources.UI_Programmig_AreasManagement_Grid_Column_WorkOrientation}
                                cell={(cellProps) => <CustomWorkOrientationCell {...cellProps} />}
                            />
                            <GridColumn field="selectedWork" width="220px" title={Translate.Resources.UI_Programmig_AreasManagement_Grid_Column_SelectedWorks}
                                cell={(cellProps: GridCellProps) => <CustomSelectTypeOfWorkCell {...cellProps} typeOfWorkOptions={typesOfWorksUsedInProject} />}
                            />
                            <GridColumn field="score" width="80px" title={Translate.Resources.UI_Programmig_AreasManagement_Grid_Column_Score}
                                cell={(cellProps: GridCellProps) => <td>
                                    <Box display="flex" flexDirection="row" alignItems="center">
                                        <Box className="pie-chart">
                                            <PieChartComponent
                                                data={{
                                                    monitoringSectionsPercent: cellProps.dataItem.monitoringSectionsPercent,
                                                    localizedRepairSectionsPercent: cellProps.dataItem.localizedRepairSectionsPercent,
                                                    generalMaintenanceSectionsPercent: cellProps.dataItem.generalMaintenanceSectionsPercent,
                                                    reinforcementSectionsPercent: cellProps.dataItem.reinforcementSectionsPercent,
                                                    rehabilitationSectionsPercent: cellProps.dataItem.rehabilitationSectionsPercent
                                                }}
                                                innerRadius={5}
                                                outerRadius={12}
                                                paddingAngle={0}
                                                cornerRadius={0}
                                                startAngle={-180}
                                                endAngle={180}
                                                cx={10}
                                                cy={8}
                                            />
                                        </Box>
                                        <Box>{cellProps.dataItem.averageScore?.toFixed(1)}</Box>
                                    </Box>
                                </td>}
                            />
                            <GridColumn field="budgetAmount" width="106px" title={`${Translate.Resources.UI_Programmig_AreasManagement_Grid_Column_Budget} (${props.currency ?? ''})`}
                                cell={(cellProps: GridCellProps) => <AreaNumericCell {...cellProps} format="n2" />}
                            />
                            <GridColumn field="costRatio" width="100px" title={`${Translate.Resources.UI_Programmig_AreasManagement_Grid_Column_CostRatio} (${props.currency}/${symbolOfSurfaceUnit})`}
                                cell={(cellProps: GridCellProps) => <AreaNumericCell {...cellProps} format="n2" />}
                            />
                            <GridColumn field="lengthInLinearMeters" width="100px" title={`${Translate.Resources.UI_Programmig_AreasManagement_Grid_Column_Linear} (${symbolOfLengthUnit})`}
                                cell={(cellProps: GridCellProps) => <AreaNumericCell {...cellProps} format="n0" />}
                            />
                            <GridColumn field="areaInSquareMeters" width="100px" title={`${Translate.Resources.UI_Programmig_AreasManagement_Grid_Column_Surface} (${symbolOfSurfaceUnit})`}
                                cell={(cellProps: GridCellProps) => <AreaNumericCell {...cellProps} format="n0" />}
                            />
                            <GridColumn field="startDate" width="114px" title={Translate.Resources.UI_Programmig_AreasManagement_Grid_Column_Begin}
                                cell={(cellProps) => <CustomDatePickerCell {...cellProps} isStartDate={true} handleDateChanged={handleStartDateChanged} />}
                            />
                            <GridColumn field="endtDate" width="114px" title={Translate.Resources.UI_Programmig_AreasManagement_Grid_Column_End}
                                cell={(cellProps) => <CustomDatePickerCell {...cellProps} isStartDate={false} handleDateChanged={handleEndDateChanged} />}
                            />
                            <GridColumn field="remarks" width="200px" title={Translate.Resources.UI_Programmig_AreasManagement_Grid_Column_Comment} />
                            <GridColumn field="" title="" width="50px"
                                headerCell={() => <></>}
                                cell={(cellProps) => <td><DeleteIcon className="delete-icon" onClick={() => handleDeleteArea(cellProps.dataItem.programmingAreaId)} /></td>}
                            />
                        </Grid>
                    </IntlProvider>
                </LocalizationProvider>
            </Box>
            <Box className="sections-count-and-actions">
                <div>
                    <span className="count">{props.selectedSectionsIds ? props.selectedSectionsIds.size : ''}</span>
                    <span className="selected-sections">{Translate.Resources.UI_Programmig_AddOrEditArea_SelectedSections}</span>
                </div>
                <Box display="flex" flexDirection="row">
                    <Button className="btn-secondary" onClick={handleCancel}>
                        {Translate.Resources.UI_Programmig_AddOrEditArea_Button_Cancel}
                    </Button>
                    <Button className="btn-primary" onClick={handleAddOrUpdateProgrammingArea}>
                        {Translate.Resources.UI_Programmig_AddOrEditArea_Button_Save}
                    </Button>
                </Box>
            </Box>
            {isConfirmCancelDialogOpened &&
                <Dialog id="cancel-area-update-dialog" open={isConfirmCancelDialogOpened}>
                    <DialogContent>
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <ReportProblemIcon className="warning-icon" />
                            <Box className="text">
                                {Translate.Resources.UI_Programming_AddOrEditArea_YourEntryWillNotBeSaved}
                            </Box>
                            <Box display="flex" flexDirection="row" justifyContent="flex-end">
                                <Button className="btn-secondary" onClick={() => setIsConfirmCancelDialogOpened(false)}>
                                    {Translate.Resources.UI_Programming_AddOrEditArea_Cancel}
                                </Button>
                                <Button className="btn-primary" onClick={props.handleCancel}>
                                    {Translate.Resources.UI_Programming_AddOrEditArea_Validate}
                                </Button>
                            </Box>
                        </Box>
                    </DialogContent>
                </Dialog>
            }
        </Box>
    );
}