import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Button, Dialog, DialogContent, IconButton, Menu, MenuItem } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import Translate from '../../../../localization/Localization';
import { PieChartComponent } from '../../../../shared/components/PieChart/PieChartComponent';
import { MeasurementSystem } from '../../../../utils/MeasurementSystem';
import { ProjectVersion } from '../../../Home/services/dataContracts/queryStack/ProjectVersion';
import { CostRatio } from '../../../ProjectSettings/services/dataContracts/queryStack/CostRatio';
import { WorkPriority } from '../../../ProjectSettings/services/dataContracts/queryStack/WorkPriority';
import { MergedProjectVersion } from '../../../RoadsCondition/models/MergedProjectVersion';
import { getScenarioStatusCssClassValues, getScenarioStatusTextValues } from '../../models/ScenarioStatus';
import ScenariosUtilities from '../../SectionsManagement/ScenariosUtilities';
import { Scenario } from '../../services/dataContracts/queryStack/Scenario';
import { MigrateScenarioDialog } from './MigrateScenarioDialog';
import './ScenarioCardStyles.scss';

interface ScenarioCardProps {
    currentProjectVersionId: number,
    scenario: Scenario,
    currency: string,
    workPriorities: WorkPriority[],
    mergedProjectAuscultationsCache: Map<number, MergedProjectVersion>,
    projectVersionsCache: Map<number, ProjectVersion>,
    costRatiosCache: Map<number, CostRatio[]>,
    handleOpenScenario: (scenarioId: number) => void,
    handleEditScenario: (scenarioId: number) => void,
    handleDeleteScenario: (scenarioId: number) => void,
    handleDuplicateScenario: (projectId: string, projectVersionId: number, projectVersionNumber: number, scenarioId: number) => void
}

export const ScenarioCardComponent = (props: ScenarioCardProps): JSX.Element => {

    const scenarioStatusTextValues = getScenarioStatusTextValues();
    const scenarioStatusCssClassValues = getScenarioStatusCssClassValues();

    const [mergedProject, setMergedProject] = useState<MergedProjectVersion>(null);
    const [costRatios, setCostRatios] = useState<CostRatio[]>(null);

    const [isDetailsOpened, setIsDetailsOpened] = useState<boolean>(false);
    const [sectionsLengthInMeters, setSectionsLengthInMeters] = useState<number>(0);
    const [sectionsSurface, setSectionsSurface] = useState<number>(0);
    const [costs, setCosts] = useState<number>(0);
    const [isConfirmDeleteScenarioDialogOpened, setIsConfirmDeleteScenarioDialogOpened] = useState<boolean>(false);
    const [isMigrateScenarioDialogOpened, setIsMigrateScenarioDialogOpened] = useState<boolean>(false);

    const [selectedProjectVersionIdToMigrate, setSelectedProjectVersionIdToMigrate] = useState<number>(null);
    const [selectedProjectVersionNumberToMigrate, setSelectedProjectVersionNumberToMigrate] = useState<number>(null);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const isMenuOpened = Boolean(anchorEl);

    useEffect(() => {
        let scenario = props.scenario;
        let mergedProject = props.mergedProjectAuscultationsCache.get(scenario.projectVersionId);
        setMergedProject(mergedProject);

        let costRatios = props.costRatiosCache.get(scenario.year);
        setCostRatios(costRatios);
    }, [props.scenario, props.mergedProjectAuscultationsCache, props.costRatiosCache, props.workPriorities]);

    useEffect(() => {
        if (mergedProject && costRatios) {
            let sectionsLengthInMeters = 0;
            let sectionsSurfaces = 0;
            let costs = 0;

            props.scenario.sections.forEach(async (element) => {
                let section = mergedProject.roadsSections.get(element.roadSectionId);

                let sectionData = ScenariosUtilities.getSectionDataWhenHasImportance(section.importance, section.score, section.traffic, props.workPriorities, costRatios);

                let lengthInMeters = Math.round(section.lengthInMeters);
                sectionsLengthInMeters += lengthInMeters ?? 0;

                let widthInMeters = section.widthInMeters;
                sectionsSurfaces += Math.round(lengthInMeters * widthInMeters) ?? 0;

                let surface = lengthInMeters && widthInMeters ? Math.round(lengthInMeters * widthInMeters) : null;
                let costRatio = sectionData.costRatio ? sectionData.costRatio.costRatioValue : null;
                let cost = surface && costRatio ? surface * costRatio : null;
                costs += cost ?? 0;
            });

            setSectionsLengthInMeters(sectionsLengthInMeters);
            setSectionsSurface(sectionsSurfaces);
            setCosts(costs);
        }
    }, [mergedProject, costRatios])

    const handleMenuClicked = (event: React.MouseEvent<HTMLElement>): void => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    }

    const handleClose = (event: React.MouseEvent<HTMLElement>): void => {
        event.stopPropagation();
        setAnchorEl(null);
    }

    const handleExpandClicked = (event: React.MouseEvent<SVGSVGElement>, expanded: boolean): void => {
        event.stopPropagation();
        setIsDetailsOpened(expanded);
    }

    const handleOpenScenario = (event: React.MouseEvent<HTMLElement>): void => {
        event.stopPropagation();
        handleClose(event);

        props.handleOpenScenario(props.scenario.scenarioId);
    }

    const handleEditScenario = (event: React.MouseEvent<HTMLElement>): void => {
        event.stopPropagation();
        handleClose(event);

        props.handleEditScenario(props.scenario.scenarioId);
    }

    const handleDeleteScenario = (event: React.MouseEvent<HTMLElement>): void => {
        event.stopPropagation();
        handleClose(event);
        setIsConfirmDeleteScenarioDialogOpened(true);
    }

    const handleDeleteCanceled = (event: React.MouseEvent<HTMLButtonElement | MouseEvent>): void => {
        event.stopPropagation();
        setIsConfirmDeleteScenarioDialogOpened(false);
    }

    const handleDeleteConfirmed = (event: React.MouseEvent<HTMLButtonElement | MouseEvent>): void => {
        event.stopPropagation();
        setIsConfirmDeleteScenarioDialogOpened(false);
        props.handleDeleteScenario(props.scenario.scenarioId);
    }

    const handleMigrateScenario = (event: React.MouseEvent<HTMLElement>): void => {
        event.stopPropagation();
        handleClose(event);
        setIsMigrateScenarioDialogOpened(true);
    }

    const handleMigrateCanceled = (event: React.MouseEvent<HTMLButtonElement | MouseEvent>): void => {
        event.stopPropagation();
        setIsMigrateScenarioDialogOpened(false);
        setSelectedProjectVersionIdToMigrate(null);
        setSelectedProjectVersionNumberToMigrate(null);
    }

    const handleMigrateConfirmed = (event: React.MouseEvent<HTMLButtonElement | MouseEvent>): void => {
        event.stopPropagation();
        handleClose(event);
        setIsMigrateScenarioDialogOpened(false);
        props.handleDuplicateScenario(props.scenario.projectId, selectedProjectVersionIdToMigrate, selectedProjectVersionNumberToMigrate, props.scenario.scenarioId);
        setSelectedProjectVersionIdToMigrate(null);
        setSelectedProjectVersionNumberToMigrate(null);
    }

    const handleSelectedProjectVersionChanged = (selectedProjectVersion: { projectVersionId: number, projectVersionNumber: number }) => {
        if (selectedProjectVersion) {
            setSelectedProjectVersionIdToMigrate(selectedProjectVersion.projectVersionId);
            setSelectedProjectVersionNumberToMigrate(selectedProjectVersion.projectVersionNumber);
        }
        else {
            setSelectedProjectVersionIdToMigrate(null);
            setSelectedProjectVersionNumberToMigrate(null);
        }
    }

    const scenario = props.scenario;

    return (
        <Box className="scenario-card" onClick={() => props.handleOpenScenario(scenario.scenarioId)}>
            <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                <Box className="scenario-title-year">{`${scenario.year} | ${scenario.label}`}</Box>
                <Box display="flex" flexDirection="row" alignItems="center">
                    <div className={`scenario-status ${scenarioStatusCssClassValues.get(scenario?.status) ?? ''}`}>{scenarioStatusTextValues.get(scenario?.status) ?? `!!${scenario?.status}!!`}</div>
                    <div>
                        <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={isMenuOpened ? 'long-menu' : undefined}
                            aria-expanded={isMenuOpened ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={handleMenuClicked}
                        >
                            <MoreVertIcon className="more-vert-icon" />
                        </IconButton>
                        <Menu
                            id="long-menu"
                            MenuListProps={{
                                'aria-labelledby': 'long-button',
                            }}
                            anchorEl={anchorEl}
                            open={isMenuOpened}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handleOpenScenario}>{Translate.Resources.UI_Scenarios_ScenarioCard_Menu_Open}</MenuItem>
                            <MenuItem onClick={handleEditScenario}>{Translate.Resources.UI_Scenarios_ScenarioCard_Menu_EditInformations}</MenuItem>
                            {props.currentProjectVersionId > scenario.projectVersionId &&
                                <MenuItem onClick={handleMigrateScenario}>{Translate.Resources.UI_Scenarios_ScenarioCard_Menu_Migrate}</MenuItem>
                            }
                            <MenuItem onClick={handleDeleteScenario}>{Translate.Resources.UI_Scenarios_ScenarioCard_Menu_Delete}</MenuItem>
                        </Menu>
                    </div>
                </Box>
            </Box>
            <hr />
            <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Box className="scenario-item">
                    <div className="item-title">{Translate.Resources.UI_Scenarios_ScenarioCard_BudgetScenario}</div>
                    <div className="value">{`${costs?.toFixedLocalized(0)} ${props.currency}`}</div>
                </Box>
                <Box className="scenario-item">
                    <div className="item-title">{Translate.Resources.UI_MaintenanceScenario_SectionsSelector_Sections}</div>
                    <div className="value">{`${scenario.sections.length}`}</div>
                </Box>
                <Box className="scenario-item">
                    <div className="item-title">{MeasurementSystem.getSymbolOfKilometersOrMiles()}</div>
                    <div className="value">{MeasurementSystem.getLenghtInKilometersOrMiles(sectionsLengthInMeters).toFixedLocalized(2)}</div>
                </Box>
                <Box className="scenario-item">
                    <div className="item-title">{`${Translate.Resources.UI_MaintenanceScenario_SectionsSelector_Surface} (${MeasurementSystem.getSymbolOfSurfaceUnit()})`}</div>
                    <div className="value">{MeasurementSystem.getSurfaceInSquaredMetersOrSquaredYards(sectionsSurface).toFixedLocalized(0)}</div>
                </Box>
                <Box className="pie-chart">
                    <PieChartComponent
                        data={{
                            monitoringSectionsPercent: scenario.monitoringPercentage,
                            localizedRepairSectionsPercent: scenario.localizedRepairPercentage,
                            generalMaintenanceSectionsPercent: scenario.generalMaintenancePercentage,
                            reinforcementSectionsPercent: scenario.reinforcementPercentage,
                            rehabilitationSectionsPercent: scenario.rehabilitationPercentage
                        }}
                        innerRadius={14}
                        outerRadius={25}
                        paddingAngle={0}
                        cornerRadius={0}
                        startAngle={-180}
                        endAngle={180}
                        cx={20}
                        cy={20}
                    />
                </Box>
                <Box display="flex" flexDirection="row" alignItems="center">
                    {isDetailsOpened ? (<ExpandLessIcon className="expand-icon" onClick={(e) => handleExpandClicked(e, false)} />) : (<ExpandMoreIcon className="expand-icon" onClick={(e) => handleExpandClicked(e, true)} />)}
                </Box>
            </Box>
            {isDetailsOpened &&
                <>
                    <hr />
                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                        <Box display="flex" flexDirection="row">
                            <div className="quality-color rehabilitation"></div>
                            <Box display="flex" flexDirection="column">
                                <div className="item-title">{Translate.Resources.UI_ActionsMenu_Quality_Rehabilitation}</div>
                                <div className="quality-item-percent">{`${scenario.rehabilitationPercentage} %`}</div>
                                <div className="quality-item-budget">{`${((scenario.totalAmount * scenario.rehabilitationPercentage) / 100).toFixedLocalized(0)} ${props.currency}`}</div>
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="row">
                            <div className="quality-color reinforcement"></div>
                            <Box display="flex" flexDirection="column">
                                <div className="item-title">{Translate.Resources.UI_ActionsMenu_Quality_Reinforcement}</div>
                                <div className="quality-item-percent">{`${scenario.reinforcementPercentage} %`}</div>
                                <div className="quality-item-budget">{`${((scenario.totalAmount * scenario.reinforcementPercentage) / 100).toFixedLocalized(0)} ${props.currency}`}</div>
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="row">
                            <div className="quality-color general-maintenance"></div>
                            <Box display="flex" flexDirection="column">
                                <div className="item-title">{Translate.Resources.UI_ActionsMenu_Quality_GeneralMaintenance}</div>
                                <div className="quality-item-percent">{`${scenario.generalMaintenancePercentage} %`}</div>
                                <div className="quality-item-budget">{`${((scenario.totalAmount * scenario.generalMaintenancePercentage) / 100).toFixedLocalized(0)} ${props.currency}`}</div>
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="row">
                            <div className="quality-color localized-repair"></div>
                            <Box display="flex" flexDirection="column">
                                <div className="item-title">{Translate.Resources.UI_ActionsMenu_Quality_LocalizedRepair}</div>
                                <div className="quality-item-percent">{`${scenario.localizedRepairPercentage} %`}</div>
                                <div className="quality-item-budget">{`${((scenario.totalAmount * scenario.localizedRepairPercentage) / 100).toFixedLocalized(0)} ${props.currency}`}</div>
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="row">
                            <div className="quality-color monitoring"></div>
                            <Box display="flex" flexDirection="column">
                                <div className="item-title">{Translate.Resources.UI_ActionsMenu_Quality_Monitoring}</div>
                                <div className="quality-item-percent">{`${scenario.monitoringPercentage} %`}</div>
                                <div className="quality-item-budget">{`${((scenario.totalAmount * scenario.monitoringPercentage) / 100).toFixedLocalized(0)} ${props.currency}`}</div>
                            </Box>
                        </Box>
                    </Box>
                </>
            }
            <hr />
            <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Box display="flex" flexDirection="column">
                    <div className="footer-label">{`${Translate.Resources.UI_Scenarios_List_Scenarios_ScenarioCard_Created_The} ${scenario.creationDate.toLocaleDateString()} ${Translate.Resources.UI_Scenarios_List_Scenarios_ScenarioCard_By} ${scenario.ownerUserFullName}`}</div>
                    {scenario.lastModificationDate &&
                        <div className="footer-label">{`${Translate.Resources.UI_Scenarios_List_Scenarios_ScenarioCard_Modified_The} ${scenario.lastModificationDate.toLocaleDateString()} ${Translate.Resources.UI_Scenarios_List_Scenarios_ScenarioCard_By} ${scenario.lastModificationUserFullName}`}</div>
                    }
                </Box>
                <Box display="flex" flexDirection="column" alignItems="flex-end">
                    {scenario.projectVersionIdMigrated ? (
                        <div className="footer-label">{`${Translate.Resources.UI_Scenarios_List_Scenarios_ScenarioCard_MigratedTo} ${scenario.videoDateTime?.toLocaleDateString()} (V${scenario.projectVersionNumber})`}</div>
                    ) : (
                        <div className="footer-label">{`${Translate.Resources.UI_Scenarios_List_Scenarios_ScenarioCard_ScenarioBasedOnSurveys} ${scenario.videoDateTime?.toLocaleDateString()}`}</div>
                    )}
                    {props.currentProjectVersionId > scenario.projectVersionId &&
                        <div className="footer-label warning">{Translate.Resources.UI_Scenarios_List_Scenarios_ScenarioCard_AnUpdateIsAvailable}</div>
                    }
                </Box>
            </Box>
            {
                isConfirmDeleteScenarioDialogOpened &&
                <Dialog id="delete-scenario-dialog" open={isConfirmDeleteScenarioDialogOpened}>
                    <DialogContent>
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <ReportProblemIcon className="warning-icon" />
                            <Box className="text">
                                {Translate.Resources.UI_Scenarios_ScenarioCard_Menu_ConfirmDeleteScenarioDialog_AreYouSureWantToRemoveScenario}
                            </Box>
                            <Box display="flex" flexDirection="row" justifyContent="flex-end">
                                <Button className="btn-secondary" onClick={(e) => handleDeleteCanceled(e)}>
                                    {Translate.Resources.UI_Scenarios_ScenarioCard_Menu_ConfirmDeleteScenarioDialog_Button_Cancel}
                                </Button>
                                <Button className="btn-primary" onClick={(e) => handleDeleteConfirmed(e)}>
                                    {Translate.Resources.UI_Scenarios_ScenarioCard_Menu_ConfirmDeleteScenarioDialog_Button_Delete}
                                </Button>
                            </Box>
                        </Box>
                    </DialogContent>
                </Dialog>
            }
            {
                isMigrateScenarioDialogOpened &&
                <MigrateScenarioDialog
                    isMigrateScenarioDialogOpened={isMigrateScenarioDialogOpened}
                    scenarioLabel={scenario.label}
                    projectId={scenario.projectId}
                    currentProjectVersionId={scenario.projectVersionId}
                    selectedProjectVersion={selectedProjectVersionIdToMigrate}
                    handleMigrateCanceled={handleMigrateCanceled}
                    handleMigrateConfirmed={handleMigrateConfirmed}
                    handleSelectedProjectVersionChanged={handleSelectedProjectVersionChanged}
                />
            }
        </Box >
    );
}