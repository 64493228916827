
export default class Utilities {

    public static formatPhoneNumber = (phoneNumberText: string): string => {
        if (!phoneNumberText)
            return null;

        var phoneNumber = Utilities.CleanPhoneNumber(phoneNumberText.trim());
        return Utilities.formatPhoneText(phoneNumber);
    }

    private static formatPhoneText = (phoneNumberText: string): string => {
        var newValue = "";
        var j = 0;
        for (var i = phoneNumberText.length - 1; i >= 0; i--) {
            if ((i <= phoneNumberText.length - 2) && (j % 2 === 0)) {
                newValue = " " + newValue;
            }
            newValue = phoneNumberText.charAt(i) + newValue;
            j++;
        }
        return newValue;
    }

    private static CleanPhoneNumber = (phoneNumberText: string): string => {
        if (!phoneNumberText)
            return null;

        return phoneNumberText
            .replace("+33", "0")
            .replace(".", "")
            .replace(" ", "")
            .replace("-", "")
    }

    public static GetCommaSeparatedString = (values: number[], keepOrder: boolean = false): string => {
        if (!values)
            return null;

        var result: string = "";
        var sortedValues = keepOrder ? values : values.sort((a, b) => { return a - b; });

        for (let i = 0; i < sortedValues.length; i++) {
            var value = values[i];
            if (i > 0) {
                result = result + ",";
            }
            result = result + value.toString();
        }

        return result;
    }

    private static _validPhoneNumberLengthsByCountryCode = new Map<string, number[]>([
        ["33", [9]], // FR
        ["34", [9]], // ES
        ["39", [10]], // IT
        ["32", [8]], // BE
        ["352", [6]], // LU
        ["49", [11]], // DE
        ["41", [11]], // CH
        ["377", [8]], // MC
        ["376", [6]], // AD
        ["44", [10, 11]], // GB
        ["351", [9]] // PT
    ]);

    private static _validPhoneNumberCountryCodePrefixes = ['+'];

    public static isValidPhoneNumber = (phoneNumber: string, validateMobilePhoneNumber = false): boolean => {
        if (!phoneNumber)
            return false;

        // On permet les espaces ' ' car on formate les numéros avec des espaces et 
        // on utilise la valeur formatée à certains endroits pour la validation.
        phoneNumber = phoneNumber.replace(/ /g, '');

        const phoneCountryCodePrefix = Utilities._validPhoneNumberCountryCodePrefixes.find(x => phoneNumber.startsWith(x));

        if (!phoneCountryCodePrefix) {
            // Pas de format avec indicatif pays
            // alors on teste pour un numéro FR
            return validateMobilePhoneNumber
                ? /^0[6,7][0-9]{8}$/.test(phoneNumber)
                : /^0[1-9][0-9]{8}$/.test(phoneNumber);
        }

        const phoneNumberWithoutCountryCodePrefix = phoneNumber.slice(phoneCountryCodePrefix.length);
        if (!/^[0-9]{6,15}$/.test(phoneNumberWithoutCountryCodePrefix)) {
            // Règle la plus générale qui contient les règles spécifiques aux indicatifs connus :
            // Tous les charactères du numéro doivent être des chiffres.
            // Il faut entre 6 et 15 caractères.
            return false;
        }

        // On peut avoir des indicatifs connus de longueur 2 ou 3 sans conflit entre eux.
        // A surveiller si on ajoute d'autres indicatifs connus dans la liste.
        const [countryCode, countryCodeValidLengths] = getCountryCodeValidLengths(2, phoneNumberWithoutCountryCodePrefix)
            ?? getCountryCodeValidLengths(3, phoneNumberWithoutCountryCodePrefix)
            ?? [null, null];

        if (countryCode && countryCodeValidLengths) {
            // ici on sait déjà que le numéro a un indicatif connu.
            if (countryCodeValidLengths.some(
                (validLength) => phoneNumberWithoutCountryCodePrefix.length === validLength + countryCode.length)) {

                // On ne vérifie que les numéros portables FR.
                // A mettre dans la config de chaque pays quand on voudra vérifier les portables d'autres pays.
                if (countryCode === '33' &&
                    validateMobilePhoneNumber &&
                    !phoneNumberWithoutCountryCodePrefix.startsWith('336') &&
                    !phoneNumberWithoutCountryCodePrefix.startsWith('337')) {
                    return false;
                }

                return true;
            }

            // numéro avec indiciatif connu
            // mais ne satisfait aucune longueur valide pour cet indicatif.
            return false;
        }

        // Ici on a passé la règle générale (indicatif + [6;15] chiffres)
        // et aucune règle spécifique ne s'est appliquée.
        return true;

        function getCountryCodeValidLengths(countryCodeLength: number, phoneNumber: string): [string, number[]] {
            const countryCode = phoneNumber.slice(0, countryCodeLength);
            const validLengths = Utilities._validPhoneNumberLengthsByCountryCode.get(countryCode);

            if (!validLengths)
                return null;

            return [countryCode, validLengths];
        }
    }

    public static validateEmailAddress = (email: string): boolean => {
        //https://regexr.com/3e48o
        var regExp = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        return regExp.test(email.trim());
    }

    public static deepClone(obj) {
        if (Array.isArray(obj)) {
            return obj.map((item) => Utilities.deepClone(item));
        } else if (typeof obj === 'object' && obj !== null) {
            const clone = {};
            for (let key in obj) {
                if (obj.hasOwnProperty(key)) {
                    clone[key] = Utilities.deepClone(obj[key]);
                }
            }
            return clone;
        } else {
            return obj;
        }
    }

    public static yearList = [2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029,
        2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039,
        2040, 2041, 2042, 2043, 2044, 2045, 2046, 2047, 2048, 2049, 2050];
}