import { faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Radio, RadioGroup, TextField, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Translate from '../../../localization/Localization';
import ToastService from '../../../ToastService';
import BusinessMessages from '../../../utils/BusinessMessages';
import Utilities from '../../../utils/Utilities';
import { Civility } from '../models/Civility';
import { SelectOptionModel } from '../models/SelectOptionModel';
import { UserDialogMode } from '../models/UserDialogMode';
import { UserGridModel } from '../models/UserGridModel';
import { AddOrUpdateUserRequestArgs } from '../services/dataContracts/controller/AddOrUpdateUserRequestArgs';
import { CustomerModel } from '../services/dataContracts/queryStack/CustomerModel';
import { UsersAdminApiClient } from '../services/UsersAdminApiClient';
import { UserPermissionsId } from '../UsersAdminView';
import './AddOrUpdateUserDialogStyles.scss';

const UserRoleIds = {
    customer: "CLI",
    customerPlus: "CLIPLUS",
    prospect: "PSP",
}

const roleOptions: SelectOptionModel[] = [
    { value: UserRoleIds.customer, label: "CLI - CLIENT" },
    { value: UserRoleIds.customerPlus, label: "CLI+ - CLIENT PLUS" },
    { value: UserRoleIds.prospect, label: "PSP - PROSPECT" }
];

interface InputTextProps {
    placeholder: string,
    value: string,
    isValid: boolean,
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void,
    onBlur?: () => void
}

const InputText = (props: InputTextProps): JSX.Element => {
    return (
        <TextField
            className={`input-text ${props.isValid ? ' valid' : ''}`}
            hiddenLabel
            size="small"
            placeholder={props.placeholder}
            value={props.value}
            onChange={(e) => props.onChange(e)}
            onBlur={props.onBlur}
        />
    );
}

interface SelectInputProps {
    placeholder: string,
    value: SelectOptionModel,
    isValid: boolean,
    isDisabled: boolean,
    options: SelectOptionModel[],
    onChange: (e: SelectOptionModel) => void
}

const SelectInput = (props: SelectInputProps): JSX.Element => {
    return (
        <Box display="flex" flexDirection="row" width="100%" justifyContent="space-between" alignItems="center" mb="10px">
            <Select
                className={`select ${props.isValid ? ' valid' : ''} ${props.isDisabled === true ? ' disabled' : ''}`}
                classNamePrefix="select_prefix"
                placeholder={props.placeholder}
                value={props.value}
                isSearchable
                menuPosition="fixed"
                options={props.options}
                onChange={(e) => props.onChange(e)}
                isClearable
                isDisabled={props.isDisabled === true}
            />
            {props.isDisabled === true &&
                <Tooltip title={Translate.Resources.UsersMessages_UserShouldNotHaveScenarios} placement="top">
                    <span>
                        <FontAwesomeIcon icon={faInfoCircle} style={{ cursor: "pointer", color: 'red' }} />
                    </span>
                </Tooltip>
            }
        </Box>
    );
}

interface AddOrUpdateUserDialogProps {
    isAddOrUpdateUserDialogOpened: boolean,
    title: string,
    mode: UserDialogMode,
    selectedUser: UserGridModel,
    handleAddOrUpdateUserDialogClosed: () => void,
    handleAfterUserAddedOrUpdated: () => void
}

export const AddOrUpdateUserDialog = (props: AddOrUpdateUserDialogProps): JSX.Element => {

    const [civility, setCivility] = useState<string>(null);
    const [lastName, setLastName] = useState<string>("");
    const [firstName, setFirstName] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [isEnabled, setIsEnabled] = useState<boolean>(true);
    const [customersList, setCustomersList] = useState<SelectOptionModel[]>([]);
    const [selectedCustomer, setSelectedCustomer] = useState<SelectOptionModel>(null);
    const [selectedRole, setSelectedRole] = useState<SelectOptionModel>(null);
    const [isUserHasScenarios, setIsUserHasScenarios] = useState<boolean>(null);
    const [hasHighwaysPermission, setHasHighwaysPermission] = useState<boolean>(false);
    const [hasCostsRatioPermission, setHasCostsRatioPermission] = useState<boolean>(false);

    useEffect(() => {

        let apiCalls: Promise<any>[] = [UsersAdminApiClient.GetCustomers()];
        if (props.selectedUser) {
            apiCalls.push(UsersAdminApiClient.CheckUserHasScenarios(props.selectedUser.userId));
        }

        Promise.all(apiCalls).then((res) => {
            let customersData: CustomerModel[] = res[0].data;
            if (customersData) {
                let customersList: SelectOptionModel[] = [];
                customersData.forEach((customer: CustomerModel) => {
                    customersList.push({
                        label: `${customer.customerId} - ${customer.name}`,
                        value: customer.customerId
                    });
                });

                setCustomersList(customersList);

                if (props.mode === UserDialogMode.Update && props.selectedUser !== null) {
                    let user = props.selectedUser;
                    setCivility(user.civility);
                    setLastName(user.lastName);
                    setFirstName(user.firstName);
                    setPhoneNumber(user.phoneNumber !== null ? user.phoneNumber : "");
                    setEmail(user.email);
                    setIsEnabled(user.isEnabled);
                    setHasHighwaysPermission(user.permissions.includes(UserPermissionsId.EditHighways));
                    setHasCostsRatioPermission(user.permissions.includes(UserPermissionsId.EditCostsRatio));

                    let role = roleOptions.find(r => r.value === user.userRoleId);
                    setSelectedRole(role);

                    let customer = customersList.find(c => c.value === user.customerId);
                    setSelectedCustomer(customer);
                }
            }

            if (props.selectedUser && props.mode === UserDialogMode.Update) {
                setIsUserHasScenarios(res[1].data as boolean);
            }
        });
    }, [props.mode, props.selectedUser])

    const handleCivilityChanged = (value: Civility): void => {
        setCivility(value);
    }

    const handleLastNameChanged = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        setLastName(e.target.value);
    }

    const handleFirstNameChanged = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        setFirstName(e.target.value);
    }

    const handlePhoneNumberChanged = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        setPhoneNumber(e.target.value);
    }

    const handleBlurPhoneNumber = (): void => {
        if (!isValidPhoneNumber(phoneNumber)) {
            ToastService.showErrorToast("Le téléphone doit être de type +33661124540");
        }
    }

    const handleEmailChanged = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        setEmail(e.target.value);
    }

    const handleCustomerChanged = (e: SelectOptionModel): void => {
        setSelectedCustomer(e);
    }

    const handleRoleChanged = (e: SelectOptionModel): void => {
        if (e?.value !== UserRoleIds.customerPlus) {
            setHasHighwaysPermission(false);
            setHasCostsRatioPermission(false);
        }
        else {
            setHasCostsRatioPermission(true);
            setHasHighwaysPermission(true);
        }
        setSelectedRole(e);
    }

    const isValidPhoneNumber = (value: string): boolean => {
        value = value.trim();
        if (value === "" || (value !== "" && Utilities.isValidPhoneNumber(value))) {
            return true;
        }

        return false;
    }

    const isValidEmail = (value: string): boolean => {
        if (value.trim() !== "" && Utilities.validateEmailAddress(value)) {
            return true;
        }

        return false;
    }

    const handleValidateClicked = (): void => {
        let userId = props.mode === UserDialogMode.Add ? email.trim() : (props.mode === UserDialogMode.Update ? props.selectedUser.userId : null);

        let permissions = {
            [UserPermissionsId.EditHighways]: hasHighwaysPermission,
            [UserPermissionsId.EditCostsRatio]: hasCostsRatioPermission
        }

        if (userId) {
            let requestArgs: AddOrUpdateUserRequestArgs = {
                userId,
                civility,
                lastName: lastName.trim(),
                firstName: firstName.trim(),
                phoneNumber: phoneNumber.trim() !== "" ? phoneNumber.trim() : null,
                email: email.trim(),
                customerId: selectedCustomer.value,
                roleId: selectedRole.value,
                isEnabled,
                permissions
            };

            let promise = props.mode === UserDialogMode.Add ? UsersAdminApiClient.AddUser(requestArgs) : (props.mode === UserDialogMode.Update ? UsersAdminApiClient.UpdateUser(requestArgs) : null);
            if (promise !== null) {
                promise.then((res) => {
                    let data = res?.data;
                    if (data) {
                        let errors = BusinessMessages.GetErrors(data);
                        if (errors.length > 0) {
                            ToastService.showErrorToast("", errors);
                            return;
                        }

                        props.handleAfterUserAddedOrUpdated();
                    }
                });
            }
        }
    }

    const isValidateButtonEnabled = civility &&
        lastName.trim() !== "" &&
        firstName.trim() !== "" &&
        isValidPhoneNumber(phoneNumber) &&
        isValidEmail(email) &&
        selectedCustomer !== null &&
        selectedRole !== null;

    return (
        <Dialog id="add-update-user-dialog" open={props.isAddOrUpdateUserDialogOpened}>
            <DialogTitle>
                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" className="title">
                    {props.title}
                    <FontAwesomeIcon icon={faTimes} onClick={props.handleAddOrUpdateUserDialogClosed} style={{ cursor: "pointer" }} />
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box display="flex" flexDirection="column" className="content">
                    <RadioGroup row value={civility} onChange={(e) => handleCivilityChanged(e.target.value as Civility)}>
                        <FormControlLabel value={Civility.mr} control={<Radio color="primary" className={`radio-button ${civility ? ' valid' : ''}`} />} label="Monsieur" className="civility" />
                        <FormControlLabel value={Civility.mrs} control={<Radio color="primary" className={`radio-button ${civility ? ' valid' : ''}`} />} label="Madame" className="civility" />
                    </RadioGroup>
                    <InputText placeholder="Nom" value={lastName} onChange={handleLastNameChanged} isValid={lastName.trim() !== "" ?? false} />
                    <InputText placeholder="Prénom" value={firstName} onChange={handleFirstNameChanged} isValid={firstName.trim() !== "" ?? false} />
                    <InputText placeholder="+33666666666" value={phoneNumber} onChange={handlePhoneNumberChanged} onBlur={handleBlurPhoneNumber} isValid={isValidPhoneNumber(phoneNumber)} />
                    <InputText placeholder="Email" value={email} onChange={handleEmailChanged} isValid={isValidEmail(email)} />
                    <FormControlLabel control={<Checkbox checked={isEnabled} color="primary" onChange={() => setIsEnabled(!isEnabled)} />} label="Actif" className="actif" />
                    <Box display="flex" flexDirection="row">
                        <FormControlLabel control={<Checkbox checked={hasHighwaysPermission} color="primary" onChange={() => setHasHighwaysPermission(!hasHighwaysPermission)} disabled={selectedRole?.value !== UserRoleIds.customerPlus} />} label="Edition Voirie" className="edit-permission-checkbox" />
                        <FormControlLabel control={<Checkbox checked={hasCostsRatioPermission} color="primary" onChange={() => setHasCostsRatioPermission(!hasCostsRatioPermission)} disabled={selectedRole?.value !== UserRoleIds.customerPlus} />} label="Edition Ratio Coûts" className="edit-permission-checkbox" />
                    </Box>
                    <SelectInput placeholder="Client" value={selectedCustomer} options={customersList} onChange={handleCustomerChanged} isValid={selectedCustomer !== null ?? false} isDisabled={isUserHasScenarios} />
                    <SelectInput placeholder="Profil" value={selectedRole} options={roleOptions} onChange={handleRoleChanged} isValid={selectedRole !== null ?? false} isDisabled={isUserHasScenarios} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Box display="flex" flexDirection="row" justifyContent="flex-end">
                    <Button className="btn-cancel" onClick={props.handleAddOrUpdateUserDialogClosed}>
                        Annuler
                    </Button>
                    <Button className={`btn-validate ${!isValidateButtonEnabled ? ' disabled' : ''}`} disabled={!isValidateButtonEnabled} onClick={handleValidateClicked}>
                        Valider
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
}
